import React from 'react';
import PropTypes from 'prop-types';
import { OutlineButton } from '../../@communityshare/ui/Button';
import { SolidButton } from '../../@communityshare/ui/Button';
import StringField from '../../forms/_fields_v2/StringField';
import TextAreaField from '../../forms/_fields_v2/TextAreaField';
import FormTemplate from '../../dialogs_common/FormTemplate';
import FormField from '../../forms/_common/FormField';
import FormTemplateButtonFooter from '../../dialogs_common/FormTemplateButtonFooter';
import SelectField from '../../forms/_fields_v2/SelectField';

Form.propTypes = {
  data: PropTypes.object,
  errors: PropTypes.object,
  showErrors: PropTypes.bool,
  alert: PropTypes.node,
  onChange: PropTypes.func,
  onSubmit: PropTypes.func,
  onClose: PropTypes.func
};

Form.defaultProps = {
  // data: {},
  errors: {},
  showErrors: false,
  onChange: () => {}
};

export default function Form(props) {
  const {
    data,
    errors,
    showErrors,
    alert,
    onChange,
    onSubmit,
    onClose
  } = props;

  return (
    <FormTemplate
      title="Update site"
      subtitle={(
        <>
          <span className="block">
            The fields you can modify are shown below. If you change the address then
            be sure to change lat/lon below as well (it will not update automatically).
          </span>
        </>
      )}
      onClose={onClose}
      alert={alert}
      body={(
        <div className="space-y-4">
          <FormField name="name" data={data} errors={showErrors && errors} onChange={onChange}>
            {(field) => (
              <StringField
                label="Name"
                placeholder="Name..."
                // description="Some text about a thing I like"
                value={field.value}
                error={field.error}
                onChange={field.onChange}
              />
            )}
          </FormField>
          <FormField name="phoneNumber" data={data} errors={showErrors && errors} onChange={onChange}>
            {(field) => (
              <StringField
                label="Phone Number"
                placeholder="Phone..."
                // description="Some text about a thing I like"
                value={field.value}
                error={field.error}
                onChange={field.onChange}
              />
            )}
          </FormField>
          <FormField name="website" data={data} errors={showErrors && errors} onChange={onChange}>
            {(field) => (
              <StringField
                label="Website"
                placeholder="URL..."
                // description="Some text about a thing I like"
                value={field.value}
                error={field.error}
                onChange={field.onChange}
              />
            )}
          </FormField>
          <FormField name="addressLine1" data={data} errors={showErrors && errors} onChange={onChange}>
            {(field) => (
              <StringField
                label="Address (street)"
                placeholder="Street..."
                // description="Some text about a thing I like"
                value={field.value}
                error={field.error}
                onChange={field.onChange}
              />
            )}
          </FormField>
          <FormField name="city" data={data} errors={showErrors && errors} onChange={onChange}>
            {(field) => (
              <StringField
                label="City"
                placeholder="City..."
                // description="Some text about a thing I like"
                value={field.value}
                error={field.error}
                onChange={field.onChange}
              />
            )}
          </FormField>
          <FormField name="state" data={data} errors={showErrors && errors} onChange={onChange}>
            {(field) => (
              <StringField
                label="State"
                placeholder="State..."
                // description="Some text about a thing I like"
                value={field.value}
                error={field.error}
                onChange={field.onChange}
              />
            )}
          </FormField>
          <FormField name="zipCode" data={data} errors={showErrors && errors} onChange={onChange}>
            {(field) => (
              <StringField
                label="ZIP Code"
                placeholder="ZIP..."
                // description="Some text about a thing I like"
                value={field.value}
                error={field.error}
                onChange={field.onChange}
              />
            )}
          </FormField>
          <FormField name="latitude" data={data} errors={showErrors && errors} onChange={onChange}>
            {(field) => (
              <StringField
                label="Latitude"
                placeholder="00.0000000000000"
                // description="Some text about a thing I like"
                value={field.value}
                error={field.error}
                onChange={field.onChange}
              />
            )}
          </FormField>
          <FormField name="longitude" data={data} errors={showErrors && errors} onChange={onChange}>
            {(field) => (
              <StringField
                label="Longitude"
                placeholder="00.0000000000000"
                // description="Some text about a thing I like"
                value={field.value}
                error={field.error}
                onChange={field.onChange}
              />
            )}
          </FormField>
        </div>
      )}
      footer={(
        <FormTemplateButtonFooter>
          <OutlineButton onClick={onClose}>
            Cancel
          </OutlineButton>
          <SolidButton onClick={onSubmit}>
            Save changes
          </SolidButton>
        </FormTemplateButtonFooter>
      )}
    />
  );
}
